<template>
  <b-modal
    :ref="modalRef"
    :id="modalRef"
    size="sm"
    hide-header-close
    header-class="border-0"
    footer-class="border-0"
    no-close-on-backdrop
    centered
  >
    <div class="d-block text-center">
      <h4>{{ header }}</h4>
      <h5>
        Overriding business rules, fees, park closures and other fields is
        recorded and reported in the system.
        <div v-if="overriddenProp != null">
          <br />
          {{ overriddenProp.name }}: {{ overriddenProp.value }}
        </div>
      </h5>
    </div>
    <template v-slot:modal-footer="{ ok }">
      <b-button class="btn" variant="primary" @click="override(ok)"
        >Yes</b-button
      >
      <b-button class="btn" variant="" @click="cancel(ok)">No</b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "AdminOverrideAlertModal",
  props: {
    header: String,
    overriddenProp: Object,
    modalRef: {
      type: String,
      default: "override-modal"
    }
  },
  methods: {
    override(ok) {
      this.$emit("override");
      ok();
    },
    cancel(ok) {
      this.$emit("cancel", this.overriddenProp);
      ok();
    }
  }
};
</script>

<style></style>
