<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title mt-2 mb-0">
        {{ title ? title : "Reservation Information" }}
      </h4>
    </div>
    <div class="card-body" v-if="reservation.spot">
      <div class="row" v-show="reservation.spot">
        <div class="col-md-4 col-sm-12">
          <span class="label">Park:</span>
          {{ reservation.spot.location.longName }}
        </div>
        <div class="col-md-4 col-sm-12">
          <span class="label">Loop:</span>
          {{ reservation.spot.loop.name }}
        </div>
        <div class="col-md-4 col-sm-12">
          <span class="label">Spot Name:</span>
          {{ reservation.spot.name }}
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 col-sm-12">
          <span class="label">Spot Type(s):</span>
          {{ spotTypes }}
        </div>
        <div class="col-md-4 col-sm-12" v-if="!isDayUse">
          <span class="label">Number of Nights:</span>
          {{ numberOfNights }}
        </div>
        <div class="col-md-4 col-sm-12" v-show="false">
          <span class="label">Fee Info:</span>
          {{ pricing }}
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 col-sm-12">
          <span class="label">Arrival Date:</span>
          {{ reservation.startDate }}
        </div>
        <div class="col-md-4 col-sm-12">
          <span class="label">Departure Date:</span>
          {{ reservation.endDate }}
        </div>
        <div class="col-md-4 col-sm-12">
          <span class="label" v-if="!!reservation.firstName"
            >Primary Occupant:</span
          >
          {{ reservation.firstName }} {{ reservation.lastName }}
        </div>
      </div>
      <div class="row" v-if="reservation.numberOfAdults">
        <div class="col-md-4 col-sm-12">
          <span class="label">Number of People:</span>
          {{ reservation.numberOfAdults }}
        </div>
        <div class="col-md-4 col-sm-12">
          <span class="label">Number of Vehicles:</span>
          {{ reservation.numberOfVehicles }}
        </div>
      </div>
      <div class="row" v-if="reservation.equipmentType">
        <div class="col-md-4 col-sm-12">
          <span class="label">Equipment Type:</span>
          {{ reservation.equipmentType }}
        </div>
        <div class="col-md-4 col-sm-12">
          <span class="label">Equipment Length (ft):</span>
          {{ reservation.equipmentLength }}
        </div>
      </div>
      <br />
      <div v-if="showCheckboxes">
        <div class="row">
          <div
            v-for="discount in discountClasses"
            :key="discount.id"
            class="col-md-4 col-sm-12"
          >
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                :value="discount.id"
                :id="`discount_class_${discount.id}`"
                v-model="reservation.discounts"
                disabled
              />
              <label
                class="form-check-label"
                :for="`discount_class_${discount.id}`"
                >{{ discount.name }}</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LocationService from "@/services/LocationService.js";
import TenantService from "@/services/TenantService.js";
import moment from "moment";
export default {
  name: "AdminReservationInfo",
  props: {
    title: { type: String, default: null },
    showCheckboxes: { type: Boolean, default: null }
  },
  data() {
    return {
      spot: {},
      pricing: "Loading...",
      discountClasses: []
    };
  },
  methods: {
    async getSpotDetails() {
      const locationService = new LocationService(this.tenantId, null);
      const response = await locationService.getSpotDetails(
        this.reservation.spotId
      );

      this.$store.commit("transaction/setReservation", {
        ...this.reservation,
        spot: response.spotDetails
      });
    },
    async getDiscounts() {
      const tenantService = new TenantService(this.tenantId);
      const discountResponse = await tenantService.getDiscountClasses();
      this.discountClasses = discountResponse.data;
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    reservation() {
      const reservation = this.$store.getters["transaction/reservation"];
      return { ...reservation, ...reservation.primaryOccupant };
    },
    numberOfNights() {
      return moment(this.reservation.endDate, "MM/DD/YYYY").diff(
        moment(this.reservation.startDate, "MM/DD/YYYY"),
        "days"
      );
    },
    spotTypes() {
      let spotTypes = "";
      if (!this.reservation.spot) {
        return spotTypes;
      }
      let spotTypeList = this.reservation.spot.spotSpotTypes;
      spotTypeList = spotTypeList.sort((a, b) =>
        a.spotType.name.trim() > b.spotType.name.trim()
          ? 1
          : a.spotType.name.trim() < b.spotType.name.trim()
          ? -1
          : 0
      );

      spotTypeList.forEach((x, i) => {
        if (i + 1 === this.reservation.spot.spotSpotTypes.length) {
          spotTypes += x.spotType.name;
        } else {
          spotTypes += x.spotType.name + ", ";
        }
      });
      return spotTypes;
    },
    isDayUse() {
      if (!this.reservation.spot || !this.reservation.spot.product) return null;

      return this.reservation.spot.product.productType.bookingType.id === 2; //Day Use
    }
  },
  created() {
    this.getSpotDetails();
    this.getDiscounts();
  }
};
</script>

<style scoped>
.label {
  font-weight: bold;
  display: inline-block;
  margin-bottom: 0.5rem;
}
.heading {
  font-size: x-large;
}
</style>
