import TenantApi from "@/generatedapiclients/src/api/TenantApi.js";

export default class TenantService {
  constructor(tenantId) {
    this._tenantId = tenantId;
    this._tenantApi = new TenantApi();
  }

  async getDiscountClasses() {
    return this._tenantApi.v1TenantTenantIdTenantDiscountClassesGet(
      this._tenantId
    );
  }

  async getPaymentTypes() {
    return this._tenantApi.v1TenantTenantIdTenantPaymenttypesGet(
      this._tenantId
    );
  }
}
